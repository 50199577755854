body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    background: #fff;
}
.logo-painel {
	height: 50px;
}
#page-wrapper {
    padding: 20px 30px;
    margin-top: -20px;
}
.services {
	background: url(../../../asset/img/bg-servicos.jpg) center no-repeat;
	padding: 60px 0;
	min-height: 540px;
}
.services a:hover {
    text-decoration: none;
}
.services a:hover h3 {
    text-decoration: underline;
}
.services h2 {
	color: #fff;
    font-weight: 300;
    margin-bottom: 45px;
}
.bg-service {
    background: #fff;
    padding: 30px 30px 18px;
    border-radius: 4px;
}
.bg-service h3 {
	margin-top: 0px;
    color: #4e1bda;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
}
.bg-service p {
	font-size: 11px;
    color: #333;
}
.copyright {
    background: #21056c;
    color: #fff;
}
.copyright p {
    margin: 8px 0 0;
}
.head-section {
    background: url(../../../asset/img/bg-head-section.jpg) center no-repeat;
    min-height: 164px;
    color: #fff;
}
.bg-destaque {
    background: url(../../../asset/img/bg-hs-welcome.jpg) center no-repeat;
    min-height: 290px;
}
.title-destaque {
    color: #fff;
    font-size: 36px;
    font-weight: 300;
    margin-top: 85px;
}
.breadcrumb {
    background-color: transparent;
}
.breadcrumb a {
    color: #fff;
}
.btn-login-header {
    position: relative;
    top: 5px;
    margin-top: -16px;
}
.bg-t-processe {
    background: rgba(33, 5, 108, 0.13);
    border-radius: 4px;
    text-align: center;
    padding: 20px;
}
.bg-t-processe .fa {
    background: #fff;
    padding: 20px;
    border-radius: 50%;
    font-size: 40px;
}
.mh600 {
    min-height: 600px;
}
.alert-comment {
    color: #333333;
    background-color: #eaeaea;
}
.l-welcome {
    padding: 30px;
}
.l-welcome {
    padding: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    min-height: 210px;
    box-shadow: 1px 1px 2px #dcdcdc;
}
a.link-category {
    text-transform: uppercase;
    font-size: 11px;
    position: absolute;
    left: 15px;
    background: rgba(240, 173, 78, 0.88);
    padding: 7px 13px;
}

.item-etiqueta {
    min-height: 260px;
    overflow: hidden;
}
